<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Users',
      }"
    />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex sub-header-options">
          <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          <router-link :to="{ name: 'create-user' }" class="cta align-right"> <plusSVG />Create user </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('username', users)" class="table-list__line">
            <button>{{ $t("overall.username") }}</button>
          </li>
          <li @click="orderListBy('first_name', users)" class="table-list__line">
            <button>{{ $t("overall.first_name") }}</button>
          </li>
          <li @click="orderListBy('last_name', users)" class="table-list__line">
            <button>{{ $t("overall.last_name") }}</button>
          </li>
          <li @click="orderListBy('email', users)" class="table-list__line">
            <button>{{ $t("overall.email") }}</button>
          </li>
          <li @click="orderListBy('phone', users)" class="table-list__line">
            <button>{{ $t("overall.phone") }}</button>
          </li>
          <li @click="orderListBy('status', users)" class="table-list__line">
            <button>Status</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="u in users" :key="u.token">
              <router-link :to="{ name: 'edit-user', params: { token: u.token } }">
                <div class="table-list__line">{{ u.username ? u.username : "-" }}</div>
                <div class="table-list__line">{{ u.first_name ? u.first_name : "-" }}</div>
                <div class="table-list__line">{{ u.last_name ? u.last_name : "-" }}</div>
                <div class="table-list__line">{{ u.email ? u.email : "-" }}</div>
                <div class="table-list__line">{{ u.phone ? u.phone : "-" }}</div>
                <div class="table-list__line">{{ u.active ? "Active" : "Pending" }}</div>
              </router-link>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("Loading Users...") }}</span>
          <span class="no-element" v-if="users.length < 1 && !loading">No users found</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import modalBus from "@/bus/modal-bus";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  components: { plusSVG },
  name: "PropertiesList",
  computed: {
    ...mapGetters({
      users: "users/users",
    }),
    numberElements() {
      return this.users.length;
    },
    numberPagination() {
      return Math.round(this.numberElements / this.elementPerPage);
    },
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        this.$axios.get("/api/users/search?search=" + this.search).then((res) => {
          console.log("res.data", res.data);
          this.users = res.data;
          this.loading = false;
          this.timer = null;
        });
      }, 300);
    },
  },
  data() {
    return {
      rangePicker: {
        from: 0,
        to: 100000,
      },
      timer: null,
      isFilters: false,
      filters: {},
      currentPagination: 1,
      pagination: 1,
      elementPerPage: 3,
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {
    ...mapActions({
      fetchUsers: "users/fetchUsers",
    }),
    orderListBy(element) {
      let _this = this;
      this.users = this.users.sort(function(a, b) {
        let A = a[element];
        let B = b[element];
        if (_this.sort.name === element && _this.sort.direction === "asc") {
          if (typeof a[element] === "string") {
            A = a[element].toUpperCase();
            B = b[element].toUpperCase();
          }
          const C = A;
          A = B;
          B = C;
        }

        return A < B ? -1 : A > B ? 1 : 0;
      });
      _this.sort.direction = _this.sort.name === element && _this.sort.direction === "asc" ? "desc" : "asc";
      _this.sort.name = element;
    },
    save() {
      this.$axios.post("/save");
    },
  },
  async mounted() {
    await this.fetchUsers();
    this.loading = false;
  },
});
</script>

<style lang="scss" scoped></style>
